


































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private label: any;
  @Prop({ type: Boolean, default: true })
  private limitRange: any;
  @Prop({ type: String, default: "obj" })
  private dataType: any;
  @Prop()
  private filters: any;
  @Watch("filters", { immediate: true })
  private filtersChange() {
    const d = JSON.parse(JSON.stringify(this.filters));
    if (this.dataType == "array") {
      this.data.startTime = d[0];
      this.data.endTime = d[1];
    } else {
      this.data = d;
    }
  }
  private data: any = {
    startTime: "",
    endTime: "",
  };
  private disabledStartDate: any = this.beginDate();
  private disabledEndDate: any = this.endDate();
  private beginDate() {
    const self = this;
    if (this.limitRange) {
      return {
        disabledDate(time: any) {
          if (self.data.endTime) {
            return (
              time.getTime() > new Date(self.data.endTime).getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      };
    } else {
      return {
        disabledDate(time: any) {
          if (self.data.endTime) {
            return time.getTime() > new Date(self.data.endTime).getTime();
          }
        },
      };
    }
  }
  private endDate() {
    const self = this;
    if (this.limitRange) {
      return {
        disabledDate(time: any) {
          if (self.data.startTime) {
            const startTime = new Date(self.data.startTime);
            if (
              startTime.getFullYear() == time.getFullYear() &&
              startTime.getMonth() == time.getMonth() &&
              startTime.getDate() == time.getDate()
            ) {
              return false;
            }
            return (
              new Date(self.data.startTime).getTime() > time.getTime() ||
              time.getTime() > Date.now()
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      };
    } else {
      return {
        disabledDate(time: any) {
          if (self.data.startTime) {
            const startTime = new Date(self.data.startTime);
            if (
              startTime.getFullYear() == time.getFullYear() &&
              startTime.getMonth() == time.getMonth() &&
              startTime.getDate() == time.getDate()
            ) {
              return false;
            }
            return new Date(self.data.startTime).getTime() > time.getTime();
          }
        },
      };
    }
  }
  private search() {
    this.$emit("search", this.data);
  }
  private mounted() {
    if (this.filters) {
      const d = JSON.parse(JSON.stringify(this.filters));
      if (this.dataType == "array") {
        this.data.startTime = d[0];
        this.data.endTime = d[1];
        this.$forceUpdate();
      } else {
        this.data = d;
      }
    }
  }
}
