import Vue from 'vue'
import Component from 'vue-class-component'
import Pagination from "@/components/paginaiton.vue";
import Empty from "@/components/empty.vue";
@Component({
  components: {
    Pagination,
    Empty,
  },
})
export class listMixin extends Vue {
  ifShowEmpty: any = false;
  filters: any = {
    search: "",//搜索
    sort_field: "创建时间",
    sort_value: "0",// 排序
    total_page: 0, // 总页码
    total_count: 0, // 总条数
    page_count: 10, //每页条数
    current_page: 1, // 当前页码
    startTime: null,
    endTime: null
  };
  getList() {
  }
  /**
    * @description 搜索
    */
  search() {
    this.filters.current_page = 1;
    this.getList();
  }
  /**
   * @description 处理排序变化
   */
  private handleSortChange(ev: any) {
    this.filters.sort_field = ev["prop"];
    this.filters.sort_value = ev["order"] === "ascending" ? "1" : "0";
    this.search();
  }
  /**
   * @description 处理翻页器的当前页变化
   */
  private handleCurrentPageChange(val: any) {
    this.filters.current_page = val;
    this.getList();
  }
  /**
   * @description 处理翻页器的每页条数变化
   */
  private handleCurrentSizeChange(val: any) {
    this.filters.current_page = 1;
    this.filters.page_count = val;
    this.getList();
  }
}