






























import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../main/components/main-header/main-header.vue";
import NavLeft from "../../main/components/nav-left/nav-left.vue";
import Satisfaction from "../components/satisfaction.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Satisfaction,
  },
})
export default class Name extends Vue {
  private cTab: any = "满意度调查分析";
  private refreshDataTime: any = "";
  private changeTab(val: any) {
    this.cTab = val;
    this.refreshDataTime = new Date().getTime();
  }
  private mounted() {
    this.refreshDataTime = new Date().getTime();
  }
}
