

























































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { PostLaiyuans } from "@/request/yichang";
import {
  GetSatisfactionCount,
  GetSatisfactionList,
  DeleteSatisfaction,
} from "@/request/statistics";
import TimeRange from "@/components/time-range/time-range.vue";
import Wenjuan from "./wenjuan.vue";
@Component({
  components: {
    TimeRange,
    Wenjuan,
  },
})
export default class Name extends mixins(listMixin) {
  @Prop()
  private type: any;
  @Prop()
  private refreshDataTime: any;
  @Watch("data", { immediate: true })
  private refreshDataTimeChange() {}
  private countData: any = {};
  private laiyuans: any = [];
  private ifShowWenjuan: any = false;
  private pinggushijian: any = [
    "近7天",
    "近1个月",
    "近3个月",
    "近半年",
    "近1年",
    "自定义",
  ];
  private data: any = [];
  private readData: any = "";
  private pinggushijianChange(e: any) {
    this.filters["完成时间"] = e;
    this.$forceUpdate();
    if (this.filters["完成时间"] !== "自定义") {
      this.search();
    }
  }
  private getCount() {
    GetSatisfactionCount(this).then((res: any) => {
      this.countData["满意"] = res["满意"] || 0;
      this.countData["比较满意"] = res["比较满意"] || 0;
      this.countData["一般"] = res["一般"] || 0;
      this.countData["不满意"] = res["不满意"] || 0;
    });
  }
  private getLaiyuan() {
    PostLaiyuans(this).then((res: any) => {
      this.laiyuans = res;
    });
  }
  private timeChange(d: any) {
    this.filters = JSON.parse(JSON.stringify(d));
    this.search();
  }
  private goRead(item: any) {
    this.readData = item;
    this.ifShowWenjuan = true;
  }
  private deleteOne(item: any) {
    this.$confirm("您是否确定删除？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const loading = this.$loading({
          lock: true,
          text: "加载中……",
          spinner: "el-icon-loading",
          customClass: "loadClass",
          background: "rgba(255, 255, 255, 0.7)",
        });
        const params: any = {
          params: {
            id: item.id,
          },
        };
        DeleteSatisfaction(this, params, loading).then((res: any) => {
          this.$message.success("删除成功！");
          this.getCount();
          this.getList();
        });
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  getList() {
    const params: any = {
      search: this.filters.search,
      来源: this.filters["来源"],
      period: this.filters["完成时间"],
      begin_date: this.filters.startTime,
      end_date: this.filters.endTime,
      result_filter: this.filters["满意度"],
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
    };
    if (this.filters["完成时间"] === "自定义") {
      params.begin_date = this.filters.startTime;
      params.end_date = this.filters.endTime;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    localStorage.setItem("manyiduFilter", JSON.stringify(this.filters));
    GetSatisfactionList(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.datas;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.filters["来源"] = "";
    this.filters["完成时间"] = "";
    this.filters["满意度"] = "";
    if (localStorage.getItem("manyiduFilter")) {
      this.filters = JSON.parse(localStorage.getItem("manyiduFilter") as any);
    }
    this.getLaiyuan();
    this.getCount();
    this.getList();
  }
}
