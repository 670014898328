import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 满意度调查结果分布
 */
const GetSatisfactionCount = (vCom: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/satisfaction_count").then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close()
            reject();
        });
    });
};
/**
 * @description 分页查询满意度调查
 */
const GetSatisfactionList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/satisfaction_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close()
            reject();
        });
    });
};
/**
 * @description 根据ID查询满意度调查详情
 */
const GetSatisfactionDetail = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/satisfaction", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close()
            reject();
        });
    });
};
/**
 * @description 删除一个满意度调查
 */
const DeleteSatisfaction = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/project/satisfaction", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            loading.close()
            reject();
        });
    });
};
export {
    GetSatisfactionCount, GetSatisfactionList, GetSatisfactionDetail,
    DeleteSatisfaction
}

