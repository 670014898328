



































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { GetSatisfactionDetail } from "@/request/statistics";
@Component({})
export default class Name extends Vue {
  @Prop()
  private readData: any;
  @Prop()
  private visible: any;
  @Watch("visible", { immediate: true })
  private visibleChange() {
    if (this.visible) {
      this.getData();
    }
  }

  private data: any = [];
  private cancel() {
    this.$emit("cancel");
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        id: this.readData.id,
      },
    };
    GetSatisfactionDetail(this, params, loading).then((res: any) => {
      this.data = res;
    });
  }
}
