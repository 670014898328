import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 统计某个医生下签约人，今日和近3日异常的各指标人数
 */
const YichangTongji = (vCom: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/today_recent_abnormal").then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 异常值处理的患者维度列表
 */
const YichangList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/handle_warnings_patient_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 某个指标数据的图标数据
 */
const YichangEcharts = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/warning_echarts", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 签约人健康指标翻页列表
 */
const getZhibiaoList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/patient_warning_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 某患者已处理异常的记录历史
 */
const getHistoryList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/handle_warning_history", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 异常处理列表
 */
const getYichangList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/patient_all_warnings", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 历史未处理列表
 */
const getUnhandledList = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/unhandled_warnings_patient_list", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 处理一次异常
 */
const sendChuli = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/handle_warning", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 未处理异常条数的翻页详情
 */
const getUnhandledDetail = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/unhandled_warning_detail", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 异常值处理-筛查监测，心脏无忧图
 */
const getStatistics = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/heart_assess_statistics", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 筛查监测-心脏无忧筛查列表
 * @param vCom Vue组件
 */
const XinzangwuyouList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/heart_assess_search", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 心脏无忧来源
 * @param vCom Vue组件
 */
const PostLaiyuans = (vCom: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/heart_assess_source_list").then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 删除心脏无忧测评
 * @param vCom Vue组件
 */
const DeleteXinzangwuyou = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.delete("api/quota/delete_heart_assess", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 查询心脏无忧评估明细
 * @param vCom Vue组件
 */
const GetXinzangwuyouDetail = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/heart_assess_detail", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 获取指标范围
 * @param vCom Vue组件
 */
const GetZhibiaoRange = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/quota/custom_quota_range", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 保存指标范围
 * @param vCom Vue组件
 */
const SaveZhibiaoRange = (vCom: any, params: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/custom_quota_range", params).then(({ data }) => {
            resHandle(vCom, data).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 心脏无忧导出
 * @param vCom Vue组件
 */
const ImportXinzang = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/quota/export_heart_assess", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
export {
    YichangTongji, YichangList, getUnhandledList, getUnhandledDetail, YichangEcharts,
    getZhibiaoList, getHistoryList, getYichangList, sendChuli, getStatistics,
    XinzangwuyouList, PostLaiyuans, DeleteXinzangwuyou, GetXinzangwuyouDetail, GetZhibiaoRange,
    SaveZhibiaoRange, ImportXinzang

}